// packages
import styled from 'styled-components';
import { FC, useState } from 'react';
// nested components
import Helmet from '../sub-components/Helmet'; // for adjusting page title in react

import ProfileButton from './ProfileButton';

import SearchIcon from '../../assets/png/Q-01.png';
import profilePhoto from '../../assets/mikemccanney.png';
import { FaArrowLeft, FaBackward, FaHome, FaSearch } from 'react-icons/fa';

// header content and logic
const Header: FC<X> = ({ className }) => {
	const [windowActive, setWindowActive] = useState<string>("none");
	return (
		<>
			<Helmet title="Doquspace" />
			{window.location.pathname !== '/login' && window.location.pathname !== '/' && (
				<header className={className}>
					<div className="header-left">
						<a href="/dashboard">News Feed</a>
						<a>My Saved Posts</a>
						<a>Profile</a>
						<a>Settings</a>
						<a>Logout</a>
					</div>

					<div className="header-right">
						<img className="search-icon" src={SearchIcon} />
						<input placeholder="search"></input>
						<ProfileButton windowActive={windowActive} />
					</div>

					<div className="header-mobile">
						<div className="mobile-banner">
							{/* <FaArrowLeft /> */}
							<a href="/dashboard">
							<FaHome size={30} />
							</a>
							<img className="profile" src={profilePhoto} />
							<h2>Mike McCanney</h2>
							<p>System Admin</p>
						</div>
						<div className="search">
							{/* <img className="search-icon" src={SearchIcon} /> */}
							<FaSearch size={30} color="white" />
							<input placeholder="search"></input>
						</div>
					</div>
				</header>
			)}
		</>
	);
};

// header styling
export default styled(Header)`
	background: var(--primary);
	background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
	backdrop-filter: blur(10px);
	box-shadow: 10px 10px 10px rgba(30, 30, 30, 0.2);
	border-radius: 10px 0 0 10px;
	position: absolute;
	display: flex;
	justify-content: space-between;
	width: 75vw;
	height: var(--header-height);
	z-index: 100;
	margin-top: 1em;
	right: 10px;
	padding-top: .1rem;
	padding-bottom: .1rem;

	.header-left {
		height: var(--header-height);
		width: var(--navbar-width);
		display: flex;
		justify-content: space-around;
		align-items: center;

		& a {
			font-weight: 600;
			color: white;
			text-align: center;
			opacity: .7;
			& img {
				display: block;
				height: 100%;
				margin: auto;
			}
		}

		& a:hover {
			cursor: pointer;
			color: var(--secondary);
		}
	}

	.header-right {
		display: flex;
		& input {
			border: none;
			margin-top: 2px;
			margin-bottom: 2px;
			border-radius: 0 10px 10px 0;
			width: 30rem;
		}

		& .search-icon {
			margin-top: 2px;
			margin-bottom: 2px;
			background: white;
			padding: 2px;
			border-radius: 10px 0 0 10px;
		}
	}

	.header-mobile {
		display: none;
	}

	@media screen and (max-width: 1400px) {

		.header-left,
		.header-right {
			font-size: small;
		}
	}

	@media screen and (max-width: 1106px) {
		width: 69vw;

		.header-left,
		.header-right {
			font-size: x-small;
		}
	}

	@media screen and (max-width: 756px) {
		width: 65vw;
		right: 0;
		left: 0;
		background-image: none;
		backdrop-filter: none;

		.header-left,
		.header-right {
			display: none;
		}

		.header-left {
			display: none;
		}

		.header-mobile {
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: absolute;
			height: var(--header-mobile-height);
			width: 100vw;


			& h2 {
				margin: auto;
				color: white;
			}

			& p {
				margin: auto;
				color: white;
				opacity: 0.5;
				font-style: oblique;
			}

			& .profile {
				border-radius: 50%;
				width: 25%;
				margin: auto;
				margin-top: 7vh;
			}

			.mobile-banner {
				display: flex;
				flex-direction: column;
				background-color: var(--primary-light);

				& svg {
					color: white;
					position: absolute;
					z-index: 100;
					margin: auto;
					margin-top: 6vh;
					right: 1.5rem;

				}
			}

			.search {
				display: flex;
				height: 20%;
				width: 80%;
				justify-content: center;
				align-items: center;
				margin: auto;
				margin-top: 1vh;
				margin-bottom: 1rem;

				& input {
					border: none;
					margin-top: 2px;
					margin-bottom: 2px;
					border-radius: 1rem;
					width: 30rem;
					& svg {
					color: white;
				
					
			}
				}
				& svg {
					margin-right: .5rem;
				}
				& .search-icon {
					width: 7vw;
					height: 7vw;
					background-color: white;
				}
			}
		}
	}
`;
