import { FC } from 'react';
import styled from 'styled-components';

interface props extends X {
    children?: any;
}

const NotesDisplay: FC<props> = ({ className, children }) => {

    return (
        <notes
            id="notes"
            className={className}
        >
            {children}
        </notes>
    );
};

export default styled(NotesDisplay)`

    width: 50%;

    & h2 {
        margin: 1rem;
    }

    & .notes {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}

    & .note {
        background-color: var(--secondary-light);
        border-radius: 1rem;
        padding: 1rem;
        margin: 1rem;
    }

    & .note h2 {
        margin: 0;
    }

    & .note p {
        margin: 0;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;

        & .note {
            margin: 0;
            margin-bottom: 1rem;
        }
    }

`