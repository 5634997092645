import styled from 'styled-components';
import { FaAndroid, FaBookOpen, FaBookmark, FaRegEye } from 'react-icons/fa';
import { SlSpeech } from 'react-icons/sl'
import { useState } from 'react';
import Button from '../../../GlobalShell/utils/Button';
import newsIcon from "../../../assets/png/News_Icon.png"

import AirHandlingPDF from '../../../assets/pdf/Air_Handling_Unit_-_IOM.pdf'

const machine1 = require("../../../assets/machines/machine1.png");
const machine2 = require("../../../assets/machines/machine2.png");
const machine3 = require("../../../assets/machines/machine3.png");



type Post = {
	src: string,
	title: string,
	machine: string,
	manufacturer: string,
	information: string,
	buildings: string[]
}

const NewsFeed: React.FC<{ className?: string }> = ({ className }) => {

	const [feedDisplay, setFeedDisplay] = useState("default");

	const feedPosts: Post[] = [
		{
			src: machine2,
			title: "Installing York Air Handling Unit",
			machine: "Air Handling Unit",
			manufacturer: "York",
			information: "Installation Procedure",
			buildings: ["Faculty/Admin Building","Student Center","Chemistry Building"]
		},
		{
			src: machine1,
			title: "Troubleshooting York Air Cooled Scroll Chiller",
			machine: "Air Cooled Scroll Chiller",
			manufacturer: "York",
			information: "Trouble Shooting Procedures",
			buildings: ["Faculty/Admin Building","Student Center","Chemistry Building"]
		},
		{
			src: machine3,
			title: "Cleaver-Brooks Boiler",
			machine: "Boiler",
			manufacturer: "Cleaver-Brooks",
			information: "Cleaning Procedures",
			buildings: ["Faculty/Admin Building","Student Center","Chemistry Building"]
		},
		{
			src: machine1,
			title: "Water Control Unit Safety Procedures",
			machine: "Water Control Unit",
			manufacturer: "York",
			information: "Safety Procedures",
			buildings: ["Faculty/Admin Building","Student Center","Chemistry Building"]
		},
		{
			src: machine2,
			title: "Washing Machine Safety",
			machine: "Washing Machine",
			manufacturer: "Milnor",
			information: "Safety Procedures",
			buildings: ["Faculty/Admin Building","Student Center","Chemistry Building"]
		},
		{
			src: machine3,
			title: "JCI Chiller Troubleshooting",
			machine: "JCI Chiller",
			manufacturer: "York",
			information: "Trouble Shooting Procedures",
			buildings: ["Faculty/Admin Building","Student Center","Chemistry Building"]
		},
		{
			src: machine1,
			title: "Water Control Unit Safety",
			machine: "Water Control Unit",
			manufacturer: "York",
			information: "Safety Procedures",
			buildings: ["Faculty/Admin Building","Student Center","Chemistry Building"]
		},
	]

	const openPDF = (src: any) => {
		const pdfURL = process.env.PUBLIC_URL + src;
		window.open(pdfURL, '_blank');
	}


	return (
		<section className={`module-display ${className}`}>
				<div className="tip">
					<img src={newsIcon} />
					<h3>Scroll through newly added & edited content</h3>
				</div>
				<div className="content">
					<div className="nav">
						<FaRegEye size={40} />
						<h4 onClick={() => setFeedDisplay("icons")}>Grid View</h4>
						<h4 onClick={() => setFeedDisplay("default")}>Regular View</h4>
						<h4 onClick={() => setFeedDisplay("compact")}>Compact View</h4>
					</div>
					{feedDisplay == "default" && feedPosts.map((post, i) => {
						return (
							<div className="single-post">
								<div className="image-container">
									<img onClick={() => openPDF(AirHandlingPDF)} src={post.src}></img>
								</div>
								<div className="text-container">
									<h3>{post.title}</h3>
									<p>Machine: {post.machine}</p>
									<p>Manufacturer: {post.manufacturer}</p>
									<p>Information: {post.information}</p>
									<div className="buildings">
									{post.buildings.map((building, i) => {
										return <p>{building}</p>
									})}
									</div>
								</div>
								<div className="icons">
									<FaRegEye size={30} />
									<FaBookmark size={30} />
									<SlSpeech size={30} />
								</div>
							</div>
						)
					})}
					{feedDisplay == "icons" && 
					<div className="icon-container">
					{feedDisplay === "icons" && feedPosts.map((post, i) => {
						return (
							<div className="single-icon">
								<div className="icon-text">
									<h5>{post.title}</h5>
									<p>{post.machine}</p>
								</div>
								<img src={post.src} />
							</div>
						)
					})}
					</div>
					}
					{feedDisplay === "compact" && 
					<table>
						<tr>
							<th>Name</th>
							<th>Machine Name</th>
							<th>Manufacturer</th>
							<th>Information</th>
							<th>Actions</th>
						</tr>
						{feedDisplay === "compact" && feedPosts.map((post, i) => {
							return (
								<tr key={i}>
									<td>{post.title}</td>
									<td>{post.machine}</td>
									<td>{post.manufacturer}</td>
									<td>{post.information}</td>
									<Button type="fill" color="var(--primary)">edit</Button>
								</tr>
							)
						})}
					</table>}
				</div>

		</section>
	);
};

export default styled(NewsFeed)`
	display: flex;
	flex-wrap: wrap;
	/* flex-direction: column; */
	justify-content: center;
	align-items: center;
	width: 100%;
	/* height: 100%; */
	overflow: auto;
	margin-top: 10vh;

	& .tip {
		background-color: var(--tertiary);
		border-radius: 1rem;
		width: 80%;
		margin-bottom: 1rem;
		padding: 1rem;
		text-align: center;
		font-style: oblique;
		/* display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center; */

		& img {
			width: 10%;
		}
	}

	& .icon-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		& :hover {
			cursor: pointer;
			transform: scale(1.05);
			color: white;
		}
	}

	& .content {
		width: 80%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		background: #fff;
		/* margin-top: 20vh; */
		border-radius: 1rem 1rem 1rem 1rem;
		filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));

		& .single-post {
			display: flex;
			width: 100%;
			border-bottom: solid gray;
			padding: 1.5rem;

			& .image-container {
				background-color: gray;
				width: 20vw;
				height: 15vw;
				border-radius: 1rem;
				display: flex;
				justify-content: center;
				align-content: center;
				padding: 10px;
				background-repeat: no-repeat;
				background-size: cover;

				& img {
					width: 100%;
					height: 100%;
					border-radius: 1rem;
					filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));
				}
			}

			& h3 {
				margin-left: 1rem;
			}

			& p {
				margin-left: 1rem;
			}

			& .buildings {
				display: flex;
				font-size: small;
				font-style: italic;
				
				
				& p {
					padding: 2px;
					color: var(--primary);
				}
			}

			& .icons {
				position: relative;
				left: 1rem;

				& svg {
					margin: 5px;
					color: var(--primary)
				}
			}
		}

		& .single-icon {
			display: flex;
			flex-direction: column;;
			width: 15vw;
			border-bottom: solid gray;
			padding: 1.5rem;
			background-color: var(--primary-light);
			margin: 1rem;
			border-radius: 1rem;
			filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));

			& img {
				width: 50%;
				height: 9vh;
				margin: auto;
				background-repeat: no-repeat;
				background-size: cover;
			}

			& .icon-text {
				display: flex;
				flex-direction: column;
				text-align: center;
				width: 100%;

				& h3 {
					margin-left: 1rem;
				}

				& p {
					margin-left: 1rem;
				}
			}


		}
	}

	.nav {
		z-index: 10;
		/* position: sticky; */
		margin: 1em;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		& h4 {
			background-color: var(--secondary-light);
			color: white;
			border-radius: 1rem;
			padding: .5rem;
			filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));
			margin-left: 1rem;
			margin-right: 1rem;
		}

		& h4:hover {
			cursor: pointer;
			background-color: var(--tertiary);
			color: black;
			transform: scale(1.1);
		}

		& svg {
			opacity: .5;
		}
	}

	table {
		max-width: 100%;
		& th {
			max-height: 5vh;
		}

		& tr {
			max-height: 5vh;
		}

		& tr:nth-child(even) {
			background-color: #dddddd;
		}

		& button {
			max-height: 5vh;
		}

		
	}


	

	& button {
		width: 12vw;
		min-width: 235px;
		height: 22vh;
		margin: 1rem;
		background: rgb(255, 255, 255);
		background: linear-gradient(162deg, rgba(255, 255, 255, 1) 0%, rgba(209, 209, 209, 1) 72%);

		& img {
			width: 80%;
			margin-bottom: 0.5rem;
		}

		& h3 {
			font-weight: 700;
		}
	}

	@media screen and (max-width: 1380px) {
		.content {
			width: 90%;

			& .single-post {
				height: 27vw;

				& .image-container {
					width: 15vw;
					min-width: 20vw;
					height: 15vw;
				}

				& .icons {
					position: relative;

					& svg {
						margin: 0;
						color: var(--primary)
					}
			}
				& h3 {
					font-size: large;
				}
			}
		}
	}


	@media screen and (max-width: 756px) {
		display: none;
	}
`;
