// DEFAULT CONTENT FOR QRL -TT
// ----------------------
import styled from 'styled-components';
import PostDisplay from './components/PostDisplay';
import { HandleUser } from './handlers/HandleUser';
import folderIcon from '../../../../assets/png/Asset_1.png';
import { FaArrowAltCircleDown, FaArrowLeft } from 'react-icons/fa';
import { ReactComponent as CloudUpload } from '../../../../assets/tool_icons/Cloud_Upload_Black.svg';
import { Link } from 'react-router-dom';
import { FaDownload, FaRegEye, FaCogs, FaTrash } from 'react-icons/fa';
import { SlSpeech } from 'react-icons/sl';
import SearchIcon from '../../../../assets/png/Q-01.png';
import { useState } from 'react';
import Notes from './components/Notes';
import AssetInfo from './components/AssetInfo';
import hillberryPhoto from '../../../../assets/hillberry.png'
import { FaSadTear } from 'react-icons/fa';

import machine1 from '../../../../assets/machines/machine1.png';
import machine2 from '../../../../assets/machines/machine2.png';
import machine3 from '../../../../assets/machines/machine3.png';

interface QRLProps extends X {
	buildingSelected?: string;
}

const QRLHome: React.FC<QRLProps> = ({ className, buildingSelected }) => {
	// grabbing user once at page load and then passing to components as prop
	const { user } = HandleUser();

	const [building, setBuilding] = useState("Hillberry/Gateway");
	const [notesVisible, setNotesVisible] = useState("hidden")
	const [tableVisible, setTableVisible] = useState("")
 
	const machines = [
		{
			machineName: "Air Handling Unit",
			building: "Student Center",
			src: machine2,
		},
		{
			machineName: "Water Control Unit",
			building: "Student Center",
			src: machine1,
		},
		{
			machineName: "Washing Machine",
			building: "Student Center",
			src: machine3,
		},
		{
			machineName: "Boiler",
			building: "Student Center",
			src: machine1,
		},
		{
			machineName: "JCI Cooler",
			building: "Student Center",
			src: machine2,
		},
		{
			machineName: "Air-Cooled Scroll",
			building: "Student Center",
			src: machine3,
		}
	]

	const buildings = [
		"Faculty/Admin Building",
		"Student Center",
		"Anthony Wayne Dr. Apts",
		"The Towers",
		"Hillberry/Gateway",
		"Undergraduate Library"
	]

	return (
		<posts className={className}>
			<div className="back"><a href="/dashboard"><FaArrowLeft size={30} color={"white"} /></a></div>
			<div className="filter">
				<div className="greeting">
					<p>Welcome to your QRL</p>
				</div>
				<button onClick={() => {
					setNotesVisible(notesVisible == "hidden" ? "" : "hidden")
					setTableVisible(tableVisible == "" ? "hidden" : "")
				}}>Building Info / Notes</button>
				<div className="crumb-container">
					<p>You are in building: <span>{buildingSelected == "" ? "unselected" : buildingSelected}</span></p>
				</div>
				{false && (
				<Link to="/create/collection">
				<button>
					Upload
					<CloudUpload />
				</button>
				</Link>
				)}
				
			</div>
			<PostDisplay user={user} />

			<div className="folder-list">
				<div className="crumb-container-mobile">
					<select onChange={(e) => {setBuilding(e.target.value)}} value={building}>
						{buildings.map((building, index) => {
							return (
								<option key={index} value={building}>{building}</option>
							)
						})}
					</select>
					<p>You are in building:</p>
					<p><span>{building}</span></p>
					<button onClick={() => { 
					setNotesVisible(notesVisible == "hidden" ? "" : "hidden")
					setTableVisible(tableVisible == "" ? "hidden" : "")
						}}>About / Info</button>
				</div>
				<div className={`mobile-about ${notesVisible}`}>
					<Notes>
						<div className="placeholder-body">
							<h2>No Notes! <FaSadTear /></h2>
							<p>No one has posted a note on this building</p>
						</div>
					</Notes>
					<AssetInfo>
						<img src={hillberryPhoto}/>
						<div>
							<h2>Hillberry/Gateway</h2>
							<p>Located on the eastern side of the campus</p>
							<ul>Machines
								<li>Air Handling Unit</li>
								<li>Water Control Unit</li>
								<li>Washing Machine</li>
								<li>Boiler</li>
								<li>JCI Cooler</li>
							</ul>
						</div>
					</AssetInfo>
				</div>
			{machines.map((machine, index) => {
				return (
					<a href="/QRL/folder" className={`folder ${tableVisible}`} key={index}>
						<img src={machine.src} />
						{machine.machineName}
					</a>
				)
			})}
			</div>
			<div className={`folder-table ${tableVisible}`}>
				<table>
					<tr>
						<th>Machine Name</th>
						<th>Building</th>
						<th>Manufacturer</th>
						<th>Floor</th>
						<th>Folder Type</th>
						<th>Notes</th>
						<th>Actions</th>
					</tr>
					<tr className="search-bars">
						<th><img src={SearchIcon} /><input placeholder='search by machine' /></th>
						<th><img src={SearchIcon} /><input placeholder='search by building' /></th>
						<th><img src={SearchIcon} /><input placeholder='search by manufacturer' /></th>
						<th><img src={SearchIcon} /><input placeholder='search by floor' /></th>
						<th><img src={SearchIcon} /><input placeholder='search by asset type' /></th>
						<th></th>
						<th></th>
					</tr>
					{machines.map((machine, index) => {
						return (
							
							<tr key={index} className="data-row">
								<td>{machine.machineName}</td>
								<td>{machine.building}</td>
								<td>York</td>
								<td>Ground Floor</td>
								<td><Link to="/QRL/folder"><img src={folderIcon} /></Link></td>
								<td><SlSpeech /></td>
								<td className="settings">
									QR 
									<FaRegEye size={20} />
									<FaDownload size={20} />
									<FaCogs size={20} />
									<FaTrash size={20} />
								</td>
							</tr>
						)
					})}
				</table>
			</div>
			<div className={`about-container ${notesVisible}`}>
				<Notes>
					<div className="placeholder-body">
						<h2>No Notes! <FaSadTear /></h2>
						<p>No one has posted a note on this building</p>
					</div>
				</Notes>
				<AssetInfo>
					<img src={hillberryPhoto}/>
					<div>
						<h2>Hillberry/Gateway</h2>
						<p>Located on the eastern side of the campus</p>
						<ul>Machines
							<li>Air Handling Unit</li>
							<li>Water Control Unit</li>
							<li>Washing Machine</li>
							<li>Boiler</li>
							<li>JCI Cooler</li>
						</ul>
					</div>
				</AssetInfo>
			</div>
		</posts>
	);
};

export default styled(QRLHome)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	/* height: 100%; */
	overflow: auto;

	& .back {
		display: none;
	}

	.about-container {
		width: 95%;
		height: 80vh;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-content: center;
		background: #fff;
		/* margin-top: 20vh; */
		border-radius: 1rem 1rem 1rem 1rem;
		filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));

		& .placeholder-body {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 75vh;

			& h2 {
				margin: 0;
				text-decoration: none;
				color: #1f1f1f;
				font-weight: 600;
				font-style: none;
			}

			& p {
				margin: 0;
				text-decoration: none;
				color: #1f1f1f;
				font-weight: 400;
				font-style: none;
			}
		}

		& content {
			display: flex;
			flex-direction: column;
			background-color: var(--primary-light);
			margin-top: 1rem;
			padding: 1rem;
			border-radius: 1rem;
			justify-content: center;
			align-items: center;
			height: 75vh;
			margin-right: 1rem;

			& div {
				background-color: #fff;
				padding: 1rem;
				border-radius: 1rem;
				margin: .2rem;
				width: 80%;
			}

			& img {
				width: 60%;
				border-radius: 1rem;
				max-width: 80%;
			}
		}
	}

	.about-container.hidden {
		display: none;
	}

	.folder-table.hidden {
		display: none;
	}

	.search-bars {
		align-items: center;
		margin: auto;
		height: 1.5rem;
		

		input {
			border: none;
			border-radius: 0 1rem 1rem 0;
			height: 1.5rem;
			margin: .5rem 0;
			width: 80%;
		}

		img {
			width: 1rem;
		}
	}

	.settings {
		& svg {
			margin-left: .5rem;
		}	
	}



	.filter {
		margin-top: 5vh;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		& button {
			border: none;
			border-radius: 1rem;
			margin: .5rem;
			padding: .5rem;
			width: 12rem;

			& svg {
				max-width: 2rem;
				margin-left: 1rem;
			}
		}

		& p {
			color: black;
			font-weight: 500;
			margin-left: 1rem;
			background-color: var(--secondary);
			padding: .5rem;
			border-radius: 1rem;
		}

		& .greeting {
			margin-top: 1rem;
			margin-right: 1rem;
			& p {
				background-color: var(--secondary);
			}
			
		}

		& .crumb-container {
			padding: .5rem;
			border-radius: 1rem;
			margin-top: 1rem;

			& span {
				background-color: var(--tertiary);
				border-radius: 1rem;
				padding: .2rem;
			}
		}
	}

	.folder-list {
		display: none;
	}

	.folder-table {
		width: 96%;
		max-height: 80vh;
		overflow-y: scroll;
		display: flex;
		flex-direction: column;
		/* justify-content: center; */
		align-content: center;
		background: #fff;
		/* margin-top: 20vh; */
		border-radius: 1rem 1rem 1rem 1rem;
		filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));
		overflow-x: auto;
	}

	table {
		max-width: 100%;
		width: 100%;
		height: 80vh;
		align-items: center;
		border-collapse: collapse;
		table-layout: fixed;
		& th {
			max-height: 5vh;
			text-align: left;
			word-wrap: break-word;
		}

		& tr {
			max-height: 5vh;

		}

		& tr:nth-child(even) {
			background-color: var(--secondary-light);
		}

		& td {
			padding: 1rem;
			text-align: left;
			word-wrap: break-word;
		}

		& button {
			max-height: 5vh;
		}

		& img {
			width: 2rem;
		}

		& button {
			border: none;
			border-radius: 1rem;
			padding-left: 1rem;
			padding-right: 1rem;
			text-align: center;
			filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));
		}
	}

	& .mobile-about.hidden {
			display: none;
	}

	& .mobile-about {
		display: none;
	}


	@media screen and (max-width: 1150px) {

		& .filter {
			margin-top: 5vh;

			& p {
				font-size: x-small;
				margin: 0;
			}
		}

		.folder-table {
			width: 100%;
			
		}
		th, td {
			font-size: small;
		}
	}

	@media screen and (max-width: 480px) {

		& .filter {
			margin-top: 5vh;

			& p {
				font-size: x-small;
				margin: 0;
			}
		}

		& .back {
			position: absolute;
			z-index: 1000;
			display: block;
			top: 1rem;
			left: 1.5rem;
		}

		.crumb-container-mobile {
			display: block;
			padding: .5rem;
			border-radius: 1rem;
			text-align: center;
			width: 90%;

				& p {
					color: black;
					font-weight: 500;
					background-color: var(--secondary);
					padding: .5rem;
					border-radius: 1rem;
				}

				& span {
					background-color: var(--tertiary);
					border-radius: 1rem;
					padding: .2rem;
				}

				& select {
					border-radius: 1rem;
					border: none;
					padding: .5rem;
					margin-bottom: .5rem;
				}
			}

		.folder-list {
			margin: auto;
			margin-top: 27vh;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;

			& button {
				border: none;
				border-radius: 1rem;
				padding: .5rem;
			}

			& img {
				width: 100%;
				border-radius: 1rem;
			}
		}

		& .mobile-about {
			display: flex;
			background-color: white;
			margin: 1rem;
			border-radius: 1rem;
			padding: 1rem;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		& .mobile-about.hidden {
			display: none;
		}

		& .folder.hidden {
			display: none;
		}

		& .about-container {
			display: none;
		}

		& .folder {
			background-color: gray;
			border-radius: 1rem;
			margin: .2rem;
			width: 43vw;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-style: normal;
			font-weight: 600;
			text-decoration: none;
			color: black;
		}

		& img {
			width: 40vw;
			margin: auto;
			padding: .2rem;
			border-radius: 1rem;
		}

		table {
			display: none;
		}

		.filter {
			display: none;
		}
	}
`;
