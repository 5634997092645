import styled from 'styled-components';
import { FunctionComponent, useState } from 'react'
import WSULogo from '../../../assets/wsu_text.png';

import NewsIcon from "../../../assets/png/News_Icon.png"
import QRLIcon from "../../../assets/png/QRL_Icon-01.png"
import NotesIcon from "../../../assets/png/Notes_Icon-01.png"
import ToolsIcon from "../../../assets/png/Tools_Icon-01.png"
import SettingsIcon from "../../../assets/png/User_manager.png"
import { Link } from 'react-router-dom';

import profileImage from "../../../assets/mikemccanney.png"

type NavOption = {
	src: string,
	name: string,
	link: string,
}

const LeftSideBar: React.FC<X> = ({ className, children }) => {

	const [selected, setSelected] = useState("");

	const activeNavOptions: NavOption[] = [
		{
			src: QRLIcon,
			name: "QRL",
			link: "/qrl",
		},
		{
			src: ToolsIcon,
			name: "Tools",
			link: "/dashboard"
		},
	]

	return (
		<section className={`user-feed ${className}`}>
			<Link to="/dashboard">
			<img className="logo" src={WSULogo} />
			</Link>
			<img className="profile" src={profileImage} />
				<div className="hello">
					<h2>Hello,</h2>
					<h2>Mike McCanney</h2>
				</div>
				<div className="quicknav-title">
					<h3>QRL QuickNav</h3>
				</div>
			<div className="app-list">
			{children}
			</div>
		</section>
	);
};

export default styled(LeftSideBar)`
	position: relative;
	background: var(--primary-light);
	min-width: var(--navbar-width);
	max-width: var(--navbar-width);
	text-align: center;
	padding: 1rem;
	color: #fff;
	/* font-style: italic; */
	overflow: auto;
	filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));

	& .hello {
		background-color: var(--secondary-light);
		border-radius: 1rem;
		filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));
	}

	& .logo {
		width: 100%;
		margin: auto;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	& .profile {
		border-radius: 50%;
		filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));
		width: 6rem;
		margin-bottom: 1rem;
	}

	& .quicknav-title {
		border-radius: 1rem;
		color: var(--tertiary);
		margin-top: 1rem;
		font-weight: 600;
		border-bottom: 2px solid white;
	}

	& .profile:hover {
		cursor: pointer;
		transform: scale(1.01);
	}

	& .logo:hover {
		cursor: pointer;
		transform: scale(1.01);
	}

	& h2 {
		margin: 0;
		text-decoration: none;
		color: #1f1f1f;
		font-weight: 600;
		font-style: none;
	}


	& .app-list {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		margin-top: 2vh;
	}

	& .app-button {
		display: block;
		/* width: 50%; */
		border-radius: 1rem;
		margin: auto;
		border: none;
		font-weight: 500;
		/* padding: .3rem; */
		filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));
		white-space: whitespace;

		& img {
			width: 100%;
		}

		&:hover {
			transform: scale(1.1);
			cursor: pointer;
		}
	}
	
	& img {
		width: 100%;
		bottom: 2em;
		justify-content: center;
		align-items: center;
		margin: auto;
	}

	@media screen and (max-width: 1086px) {
		min-width: 30vw;
		max-width: 30vw;
		
	}

	@media screen and (max-width: 756px) {
		display: none !important;
	}
`;
