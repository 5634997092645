import styled from 'styled-components';
import { FC, useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';

import Notes from './Notes';
import Support from './Support';
import UserSettings from './UserSettings';

// icons
import Button from '../utils/Button';

const profileImage = require('../../assets/mikemccanney.png')

const ProfileButton: FC<{ className?: string, windowActive: string }> = ({ className, windowActive }) => {

	const [settings, setSettings] = useState(false);
	const [notes, setNotes] = useState(false);
	const [support, setSupport] = useState(false);

	const [translate, setTranslate] = useState("75vw");
	const [dropOverride, setDropOverride] = useState("");

	const handleDropOverride = () => {
		dropOverride == "" ? setDropOverride("translate(-80vw, 0px)") : setDropOverride("");
	}

	useEffect(() => {
		if (settings) {
			setNotes(false);
			setSupport(false);
		}
		if (notes) {
			setSettings(false);
			setSupport(false);
		}
		if (support) {
			setSettings(false);
			setNotes(false);
		}
	},[settings, notes, support]);


	return (
		<Dropdown className={className}>
			<Dropdown.Toggle>
				<img src={profileImage} onClick={() => handleDropOverride()} />
			</Dropdown.Toggle>

			<Dropdown.Menu style={{ translate: `${translate}` }}>
				<div className="menu" >
					<left>
						<div className="profile-flavor">
							<h2><span>Hello, </span> <br />Mike McCanney</h2>
							<img className="profile" src={profileImage} />
						</div>
						<h3 onClick={() => { 
							notes ? setNotes(false) : setNotes(true);
							translate == "75vw" ? setTranslate("") : setTranslate("75vw");
							}}>Shift Notes</h3>
						<li>
							<ul>Electrical Notes</ul>
							<ul>Mechanical Notes</ul>
							<ul>Pneumatic Notes</ul>
							<ul>General Notes</ul>
						</li>

						<h3 onClick={() => { 
							settings ? setSettings(false) : setSettings(true);
							translate == "75vw" ? setTranslate("") : setTranslate("75vw");
							}}>User Settings</h3>
						<li>
							<ul>Privacy & Safety</ul>
							<ul>Account</ul>
							<ul>Support</ul>
							<ul>Log Out</ul>
						</li>

						<h3 onClick={() => {
							support ? setSupport(false) : setSupport(true);
							translate == "75vw" ? setTranslate("") : setTranslate("75vw");
							}}>Account Settings</h3>
						<li>
							<ul>Change Username</ul>
							<ul>Change Profile Image</ul>
							<ul>View Credentials</ul>
							<ul>View Status</ul>
						</li>
					</left>
					<right>
						<Notes selected={notes} />
						<UserSettings selected={settings} />
						<Support selected={support} />
					</right>
				</div>

			</Dropdown.Menu>
		</Dropdown>
	);
};

export default styled(ProfileButton)`
	display: absolute !important;
	height: 100%;

	& img {
		width: 2.5vw;
		height: 2.5vw;
		border-radius: 50%;
		object-fit: cover;
		background-size: cover;
  		background-repeat: no-repeat;
		/* border: white 2px solid; */
		filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));
	}

	.menu {
		display: flex;
	}

	& button {
		height: 100%;
		border: none;
		border-radius: 50%;
		margin: auto 5px;
		padding: 0;
		background: #ffffff1f;

		&::after {
			display: none;
		}
	}

	& .profile {
		width: var(--header-height);
		border-radius: var(--header-height);
		object-fit: cover;
		background-size: cover;
  		background-repeat: no-repeat;
	}

	.dropdown-menu.show {
		background: #181818;
		width: 100vw;
		height: 95vh;
		border-radius: 1rem;
		
		& .profile-flavor {
			color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: left;
			margin-bottom: 2em;


			& h2 {
				width: 50%;
				font-size: x-large;
			}



			& span {
				font-style: italic;
			}

			& img {
				width: 20%;
				height: auto;
				margin-left: 3em;
			}
		}

		& button {
			width: 10em;
			height: 3em;
			background: orange;
			color: #fff;
			border-radius: 1rem;
			margin: 1rem;
		}

		& h3 {
			color: white;
			text-align: right;
			margin-right: 1.8rem;
		}

		& h3:hover {
			color: var(--primary-light);
			cursor: pointer;
			transform: scale(1.01);
		}

		& ul {
			color: white;
			text-align: right;
			margin-right: 1.8rem;
			opacity: 60%;
			
		}

		& ul:hover {
			color: var(--secondary);
			cursor: pointer;
			transform: scale(1.01);
		}
	}

	left {
		width: 20%;
	}

	right {
		border-left: solid gray;
		margin-left: .5em;
	}


`;
