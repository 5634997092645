import React, { FC } from "react";
import styled from "styled-components";



import settingsIcon from "../../../assets/png/Settings_Icon-01.png"
import toolsIcon from "../../../assets/png/Tools_Icon-01.png"
import newsIcon from "../../../assets/png/News_Icon.png"
import QRLIcon from "../../../assets/png/QRL_Icon-01.png"
import homeIcon from "../../../assets/png/Home_Icon-01.png"
import notesIcon from "../../../assets/png/Notes_Icon-01.png"
import userManager from "../../../assets/png/User_manager.png"
import { Link } from "react-router-dom";

interface props {
    className?: string;
}

const MobileAppList: FC<props> = ({ className }) => {

    const apps = [
        {
            name: "QRL",
            icon: QRLIcon,
            link: "/QRL"
        },
        {
            name: "News",
            icon: newsIcon,
            link: "/news"
        },
        {
            name: "Tools",
            icon: toolsIcon,
            link: "/dashboard"
        },
        {
            name: "Account Settings",
            icon: settingsIcon,
            link: "/dashboard"
        },
        {
            name: "Profile",
            icon: homeIcon,
            link: "/dashboard"
        },
        {
            name: "Notes",
            icon: notesIcon,
            link: "/dashboard"
        },
        {
            name: "User Settings",
            icon: userManager,
            link: "/dashboard"
        },
    ]

    return (
        <section className={className}>
            <div className="list-container">
                {apps.map((app, index) => {
                    const Icon = app.icon;
                    return (
                        <a href={app.link} className="app" key={index}>
                            <img className="icon" src={Icon} />
                        </a>
                    )
                })}
            </div>
        </section>
    )
}

export default styled(MobileAppList)`
    display: none;
    /* width: 100vw; */

    @media screen and (max-width: 756px) {
        display: flex;
        flex-direction: column;
        height: 100vh;
        /* width: 100vw; */
        margin-top: 23vh;

        & .list-container {
            margin-top: 3rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }

        & .app {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 40vw;
            background-color: var(--primary);
            color: #fff;
            padding: .5em;

            & img {
                max-width: 100%;
                filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));
            }
        }
    }
`;