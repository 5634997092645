import styled from "styled-components";
import profilePhoto from '../../assets/profile.jpg'

interface props {
    className?: string;
    selected: boolean;
}

const UserSettings = ({ className, selected }: props) => {
    return (
        <div className={className}>
            { selected ?
            <div className="settings">
                <div className="forms">
                <h1>User Settings</h1>

                <form>
                    <h2>Update Personal Information</h2>
                    <label>First Name</label>
                    <input placeholder="Update First Name"></input>
                    <label>Last Name</label>
                    <input placeholder="Update Last Name"></input>
                    <label>Email</label>
                    <input placeholder="Update Email"></input>
                    <label>Confirm Email</label>
                    <input placeholder="Confirm Email"></input>
                    <button>Submit</button>
                </form>

                <form>
                    <h2>Update Password</h2>
                    <label>Current Password</label>
                    <input placeholder="Current Password"></input>
                    <label>New Password</label>
                    <input placeholder="New Password"></input>
                    <label>Confirm Password</label>
                    <input placeholder="Confirm Password"></input>
                    <button>Submit</button>
                </form>
                </div>
                <div className="profile-photo">
                    <h2>Profile Image</h2>
                    <img src={profilePhoto} />
                </div>
            </div>
            : null }
        </div>
    )
}

export default styled(UserSettings)`

    .settings {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .profile-photo {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 2em;

        & h2 {
            color: white;
        }

        & img {
            width: 20em;
            height: 20em;
        }
    }

    h1 {
        color: white;
    }

    .forms {
        display: flex;
        flex-direction: column;
        align-items: right;
        margin-left: 2em;

        & h2 {
            color: white;
        }

        & label {
            color: white;
        }

        & form {
            display: flex;
            flex-direction: column;
        }
    }
`;