import { Route, Routes } from 'react-router-dom';

import Home from '../modules/I-HOME/page';
import Dashboard from '../modules/II-DASHBOARD/page';
import QUIKBot from '../modules/III-QUIKBOT/page';
import Profile from '../modules/X-PROFILE/page';
import Login from '../modules/X-LOGIN/page';
import Analytics from '../modules/V-ANALYTICS/page';
import { News } from '../modules/X-NEWS/module';
import { QRL } from '../modules/III-QRL/module';
import { Admin } from '../modules/X-ADMIN/module'
import Notes from '../modules/X-NOTES/page';
import Settings from '../modules/X-SETTINGS/page';
import { Create } from '../modules/X-CREATE/module';

const Router = () => {
	return (
		<Routes>
			<Route
				path="/"
				element={<Dashboard />}
			/>
			<Route
				path="/dashboard"
				element={<Dashboard />}
			/>
			<Route
				path="/profile"
				element={<Profile />}
			/>
			<Route
				path="/settings"
				element={<Settings />}
			/>
			<Route
				path="/notes"
				element={<Notes />}
			/>
			<Route
				path="/login"
				element={<Login />}
			/>
			<Route
				path="/QRL/*"
				element={<QRL />}
			/>
			<Route
				path="/analytics"
				element={<Analytics />}
			/>
			<Route
				path="/profile"
				element={<Profile />}
			/>
			<Route
				path="/news"
				element={<News />}
			/>
			<Route
				path="/admin/*"
				element={<Admin />}
			/>
			<Route
				path="/create/*"
				element={<Create />}
			/>
		</Routes>
	);
};

export default Router;
