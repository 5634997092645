import styled from 'styled-components';

import LeftSideBar from './components/LeftSideBar';
import WidgetBar from './components/WidgetBar';
import Header from '../../GlobalShell/Header';
import NewsFeed from './components/NewsFeed';
import auth from '../X-LOGIN/controllers/auth';
import { useEffect, useState } from 'react';
import MobileAppList from './components/MobileAppList';
import SearchIcon from '../../assets/png/Q-01.png';
import { Link } from 'react-router-dom';

const Dashboard: React.FC<{ className?: string }> = ({ className }) => {
	// const loggedIn = auth.loggedIn();
	const loggedIn = true;
	const [selected, setSelected] = useState("");

	const activeNavOptions = [
		"Faculty/Admin Building",
		"Student Center",
		"Anthony Wayne Dr. Apts",
		"The Towers",
		"Hillberry/Gateway",
		"Undergraduate Library",
		"Charles Lang Freer House",
		"Applebaum Building",
		"Art Building",
		"Cohn",
		"Detroit Opera House",
		"Elliman Building",
		"Knapp Building",
		"McGregor Memorial Conference Center",
		"Scott Hall",
		"Welcome Center",
	];

	
	useEffect(() => {
		if (loggedIn) {
			console.log(loggedIn);
			console.log(auth.getUsername());
		}
	})
	return (
		<>
		{loggedIn ? 
			<div className={`dashboard ${className}`}>
				<Header />
				<LeftSideBar>
				<div className="QRL-list">
						<button className="QRL-drop">
							<img className="search" src={SearchIcon} /><input placeholder='Search for a building' />
						</button>
						<h2>Buildings</h2>
						
						{activeNavOptions.map((option, i) => {
							return (
								<a href="/QRL">
								<div onClick={() => {
									setSelected(option);
								}} className={option == selected ? "supercollection selected" : "supercollection"} key={i}>
									<p>{option}</p>
								</div>
								</a>
							);
						  })}

					</div>
				</LeftSideBar>
				<NewsFeed />
			
				<MobileAppList />
			</div>
		: null }		
		</>
	);
};

export default styled(Dashboard)`
	display: flex;
	/* width: 100%; */
	/* height: 100%; */
	background-color: var(--primary);

	& .QRL-list {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	& a {
		text-decoration: none;
	}

	& .supercollection {
		background-color: var(--tertiary);
		border: none;
		margin: .5rem;
		border-radius: 1rem;
		filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));
		justify-content: center;
		align-items: center;
		& p {
			color: black;
			text-decoration: none;
		}
	}

	& .selected {
		background-color: var(--secondary);
	}

	& .QRL-drop {
		border: none;
		margin: .5rem;
		border-radius: 1rem;
		filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));
		background-color: white;
		color: var(--primary);
		font-weight: 500;
		align-content: center;
		display: flex;
		align-items: center;

		& img {
			width: 1.5rem;
			margin: 0;
		}
		& input {
			border: none;
			border-radius: 0 1rem 1rem 0;
			height: 1.5rem;
			margin: .5rem 0;
		}
	}

	& button:hover {
		transform: scale(1.01);
	}

	@media screen and (max-width: 480px) {
		display: block;
	}
`;
