import { FC, useState } from 'react';
import styled from 'styled-components';

import pdfIcon from '../../../../assets/png/PDF_Icon-01.png'
import folderIcon from '../../../../assets/png/Asset_1.png'
import machineImage from '../../../../assets/machines/machine2.png'
import { FaArrowAltCircleDown, FaArrowLeft } from 'react-icons/fa';
import { ReactComponent as CloudUpload } from '../../../../assets/tool_icons/Cloud_Upload_Black.svg'
import { Link } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../../../../assets/tool_icons/SearchBar.svg';

import { FaDownload, FaRegEye, FaCogs, FaTrash } from 'react-icons/fa';
import { SlSpeech } from 'react-icons/sl';

import AirHandlingPDF from '../../../../assets/pdf/Air_Handling_Unit_-_IOM.pdf'
import AirHandlingPDF2 from '../../../../assets/pdf/SMP 1_YORK AIR HANDLING UNIT_FAN  HOUSING CLEANING_FINAL PROCEDURE.pdf'
import AirHandlingPDF3 from '../../../../assets/pdf/SMP 2_YORK AIR HANDLING UNIT_FAN  HOUSING CLEANING_FINAL PROCEDURE.pdf'
import AirHandlingPDF4 from '../../../../assets/pdf/SMP 3_YORK AIR HANDLING UNIT LUBRICATION.pdf'
import AirHandlingPDF5 from '../../../../assets/pdf/appendix a-b ahu.pdf'
import AirHandlingPDF6 from '../../../../assets/pdf/section 1 york ahu - general information and safety.pdf'
import AirHandlingPDF7 from '../../../../assets/pdf/section 2 york ahu - start up and operation.pdf'
import AirHandlingPDF8 from '../../../../assets/pdf/section 3 york ahu - maintenence.pdf'
import AirHandlingPDF9 from '../../../../assets/pdf/section 4 york ahu - service and repair.pdf'
import AirHandlingPDF10 from '../../../../assets/pdf/section 5 york ahu - wiring diagrams.pdf'

import hillberryPhoto from '../../../../assets/hillberry.png'
import { FaSadTear } from 'react-icons/fa';

import SearchBar from '../../QRLShell/components/SearchBar';
import Notes from '../Home/components/Notes';
import AssetInfo from '../Home/components/AssetInfo';

import profileImage from '../../../../assets/mikemccanney.png'

interface props extends X {
	buildingSelected?: string;
}

const Folder: FC<props> = ({ className, buildingSelected }) => {

	const [notesVisible, setNotesVisible] = useState("hidden")
	const [tableVisible, setTableVisible] = useState("")

	const blobs = [
		{
			machine: "Air-Cooled Scroll",
			manufacturer: "York",
			floor: "Ground Floor",
			assetTitle: "Installation and Assembly",
			building: "Student Center",
			assetType: "Manual",
			src: AirHandlingPDF
		},
		{
			machine: "JCI Chiller",
			manufacturer: "York",
			floor: "Ground Floor",
			assetTitle: "York Air Handling Unit Fan Housing Cleaning 1",
			building: "Student Center",
			assetType: "SMP",
			src: AirHandlingPDF2
		},
		{
			machine: "Air Handling Unit",
			manufacturer: "York",
			floor: "Basement",
			assetTitle: "York Air Handling Unit Fan Housing Inspection 2",
			building: "Student Center",
			assetType: "SMP",
			src: AirHandlingPDF3
		},
		{
			machine: "Air Handling Unit",
			manufacturer: "York",
			floor: "Basement",
			assetTitle: "York Air Handling Unit Motor Lubrication 3",
			building: "Student Center",
			assetType: "SMP",
			src: AirHandlingPDF4
		},
		{
			machine: "Boiler",
			manufacturer: "Cleaver-Brooks",
			floor: "Ground Floor",
			assetTitle: "Air Handling Units Start-Up Checklist",
			building: "Student Center",
			assetType: "Appendix",
			src: AirHandlingPDF5
		},
		{
			machine: "Water Control Unit",
			manufacturer: "York",
			floor: "2nd Floor",
			assetTitle: "Operation And Maintenance",
			building: "Student Center",
			assetType: "SMP",
			src: AirHandlingPDF6
		},
		{
			machine: "Washing Machine",
			manufacturer: "Milnor",
			floor: "2nd Floor",
			assetTitle: "Section 2 - Start Up & Operation",
			building: "Student Center",
			assetType: "SMP",
			src: AirHandlingPDF7
		},
		{
			machine: "JCI Chiller",
			manufacturer: "York",
			floor: "Ground Floor",
			assetTitle: "Section 3 - Maintenance",
			building: "Student Center",
			assetType: "Manual",
			src: AirHandlingPDF8
		},
		{
			machine: "JCI Chiller",
			manufacturer: "York",
			floor: "Ground Floor",
			assetTitle: "Section 4 - Service & Repair",
			building: "Student Center",
			assetType: "Manual",
			src: AirHandlingPDF9
		},
		{
			machine: "JCI Chiller",
			manufacturer: "York",
			floor: "Ground Floor",
			assetTitle: "Section 5 - Wiring Diagrams",
			building: "Student Center",
			assetType: "Manual",
			src: AirHandlingPDF10
		},
	]

	const noteElements = [
        {
            creator: "Mike McCanney",
            datePosted: "10/15/23",
			body: "The York Air Handling Unit has many moving components that require preventive maintenance to keep working properly. In the case of unfamiliar noises coming from the machine please look at the cleanliness of the machine and check if the machine needs any maintenance."
        },
		{
            creator: "Mike McCanney",
            datePosted: "10/15/23",
			body: "Forward curved fan wheels are more susceptible to dirt accumulation than other wheels. Dirt and debris on fan wheels and shafts may adversely affect the balance of the fan assembly."
        },
    ]

	

	const openPDF = (src: any) => {
		const pdfURL = process.env.PUBLIC_URL + src;
		window.open(pdfURL, '_blank');
	}

    return (
        <folder className={className}>
			<div className="back"><a href="/QRL"><FaArrowLeft size={30} color={"white"} /></a></div>
            <div className="filter">
				<div className="greeting">
					<p>Welcome to your QRL</p>
				</div>
				<button onClick={() => {
					setNotesVisible(notesVisible == "hidden" ? "" : "hidden")
					setTableVisible(tableVisible == "" ? "hidden" : "")
				}}>Machine Info / Notes</button>
				<div className="crumb-container">
					<p>You are in building: <span>{buildingSelected}</span> and machine: <span>Air Handling Unit</span></p>
				</div>
				{false && (
					<Link to="/create/blob">
					<button>
						Upload
						<CloudUpload />
					</button>
					</Link>
				)}

			</div>
            <div className="blob-list">
				<div className="crumb-container-mobile">
					<p>You are in building: <span>{buildingSelected}</span></p>
					<p>and machine: <span>Air Handling Unit</span></p>
					<button onClick={() => { 
					setNotesVisible(notesVisible == "hidden" ? "" : "hidden")
					setTableVisible(tableVisible == "" ? "hidden" : "")
						}}>About / Info</button>
				</div>
				<div className={`mobile-about ${notesVisible}`}>
					<Notes>
						{noteElements.map((note, i) => {
							return (
								<div className="note">
									<h2><span><img src={profileImage} /></span>{note.creator}</h2>
									<em>{note.datePosted}</em>
									<p>{note.body}</p>
								</div>
								)
							})
						}
					</Notes>
					<AssetInfo>
						<img src={hillberryPhoto}/>
						<div>
							<h2>Hillberry/Gateway</h2>
							<p>Located on the eastern side of the campus</p>
							<ul>Machines
								<li>Air Handling Unit</li>
								<li>Water Control Unit</li>
								<li>Washing Machine</li>
								<li>Boiler</li>
								<li>JCI Cooler</li>
							</ul>
						</div>
					</AssetInfo>
				</div>
            {blobs.map((blob, index) => {
                return (
                    <div className="blob" key={index}>
                        <img src={pdfIcon} onClick={() => openPDF(blob.src)} />
                        {blob.assetTitle}
                    </div>
                )
            })}
            </div>
            <div className={`blob-table ${tableVisible}`}>
				<table>
					<tr>
						<th>Asset Title</th>
						<th>Asset Type</th>
						{/* <th>Manufacturer</th>
						<th>Floor #</th> */}
						<th>Document Type</th>
						<th>Notes</th>
						<th>Actions</th>
					</tr>
					<tr className="search-bars">
						<th><SearchIcon /><input placeholder='Search by Asset Type' /></th>
						{/* <th><SearchIcon /><input placeholder='Search by Manufacturer' /></th>
						<th><SearchIcon /><input placeholder='Search by Floor' /></th> */}
						<th><SearchIcon /><input placeholder='Search by Asset Name' /></th>
						<th><SearchIcon /><input placeholder='Search by Document Type'/></th>
						<th></th>
						<th></th>
					</tr>
					{blobs.map((blob, index) => {
						return (
							
							<tr key={index}>
								<td className="asset-link"><Link to="/QRL/blob">{blob.assetTitle}</Link></td>
								<td>{blob.assetType}</td>
								{/* <td>{blob.manufacturer}</td>
								<td>{blob.floor}</td> */}
								<td><img src={pdfIcon} onClick={() => openPDF(blob.src)} /> PDF</td>
								<td><SlSpeech /></td>
								<td className="settings">
									QR 
									<FaRegEye size={20} />
									<FaDownload size={20} />
									<FaCogs size={20} />
									<FaTrash size={20} />
								</td>
							</tr>
						)
					})}
				</table>
			</div>
			<div className={`about-container ${notesVisible}`}>
				<Notes>
					<h2>Notes</h2>
					{noteElements.map((note, i) => {
						return (
							<div className="note">
								<h2><span><img src={profileImage} /></span>{note.creator}</h2>
								<em>{note.datePosted}</em>
								<p>{note.body}</p>
							</div>
							)
						})
					}
				</Notes>
				<AssetInfo>
					<img src={machineImage}/>
					<div>
						<h2>Air Handling Unit</h2>
						<em>Manufactured by York</em>
					</div>
				</AssetInfo>
			</div>
        </folder>
    )
}

export default styled(Folder)`
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	overflow: auto;

	& .back {
		display: none;
	}
	

	.about-container {
		width: 95%;
		height: 80vh;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-content: center;
		background: #fff;
		/* margin-top: 20vh; */
		
		border-radius: 1rem 1rem 1rem 1rem;
		filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));

		& content {
			
			display: flex;
			flex-direction: column;
			background-color: var(--primary-light);
			margin: 1rem;
			padding: 1rem;
			border-radius: 1rem;
			justify-content: center;
			align-items: center;
			height: 75vh;
			width: 50%;

			ul {
				& p {
					border-radius: .5rem;
					font-weight: 500;
				}
			}

			& em {
				background-color: var(--secondary-light);
				padding: .2rem;
				border-radius: .5rem;
				font-weight: 500;
			}

			& img {
					width: 60%;
					margin-bottom: 2rem;
					border-radius: 1rem;
			}

			& div {
				background-color: #fff;
				padding: 1rem;
				border-radius: 1rem;
				margin: .2rem;
				width: 80%;
			}
		}

		& .note {
			max-height: 30vh;
			overflow-y: scroll;
			& img {
				border-radius: 50%;
				width: 4rem;
				filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));
				margin-right: 1rem;
			}
		}
	}

	.about-container.hidden {
		display: none;
	}

	.blob-table.hidden {
		display: none;
	}



	.search-bars {
		align-items: center;
		margin: auto;

		input {
			border: none;
			border-radius: 0 1rem 1rem 0;
			height: 1.5rem;
			margin: .5rem 0;
			width: 80%;
		}

		svg {
			/* width: 4.5%; */
			padding: .2rem;
			height: 1.5rem;
			margin: .5rem 0;
		}
	}

	.filter {
		margin-top: 5vh;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		& button {
			border: none;
			border-radius: 1rem;
			margin: .5rem;
			padding: .5rem;
			width: 12rem;

			& svg {
				max-width: 2rem;
				margin-left: 1rem;
			}
		}

		& p {
			color: black;
			font-weight: 500;
			margin-left: 1rem;
			background-color: var(--secondary);
			padding: .5rem;
			border-radius: 1rem;
		}

		& .greeting {
			margin-top: 1rem;
			margin-right: 1rem;
			& p {
				background-color: var(--secondary);
			}
			
		}

		& .crumb-container {
			padding: .5rem;
			border-radius: 1rem;
			margin-top: 1rem;

			& span {
				background-color: var(--tertiary);
				border-radius: 1rem;
				padding: .2rem;
			}
		}
	}

	.blob-list {
		display: none;
	}

	.settings {
		& svg {
			margin-left: .5rem;
		}	
	}

	.blob-table {
		width: 96%;
		max-height: 80vh;
		overflow-y: scroll;
		display: flex;
		flex-direction: column;
		/* justify-content: center; */
		align-content: center;
		background: #fff;
		/* margin-top: 20vh; */
		border-radius: 1rem 1rem 1rem 1rem;
		filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));
	}

	table {
		max-width: 100%;
		height: 80vh;
		align-items: center;
		border-collapse: collapse;
		table-layout: fixed;
		& th {
			max-height: 5vh;
			text-align: left;
			text-align: left;
			word-wrap: break-word;
		}

		& tr {
			max-height: 5vh;
		}

		& tr:nth-child(even) {
			background-color: var(--secondary-light);
		}

		& td {
			padding: 1rem;
			text-align: left;
			word-wrap: break-word;
		}

		& tr:hover {
			cursor: pointer;
			background-color: var(--secondary);
		}

		& button {
			max-height: 5vh;
		}

		& img {
			width: 4rem;
		}

		& img:hover {
			cursor: pointer;
		}

		& button {
			border: none;
			border-radius: 1rem;
			padding-left: 1rem;
			padding-right: 1rem;
			text-align: center;
			filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));
		}

		& .asset-link {
			& a {
				color: black;
				text-decoration: none;
			}
		}
	}

	.crumb-container-mobile {
			display: none;
	}

	@media screen and (max-width: 1300px) {
		& .filter {
			margin-top: 5vh;
			width: 100%;

			& button {
				font-size: small;
			}

			& p {
				font-size: x-small;
			}
		}

		.folder-table {
			width: 100%;
			
		}
		th, td {
			font-size: small;
		}
	}

	@media screen and (max-width: 1086px) {
		& .filter {
			margin-top: 5vh;

			& p {
				font-size: x-small;
				margin: 0;
			}

			& button {
				font-size: x-small;
			}
		}
	
	}

	@media screen and (max-width: 480px) {

		& .about-container {
			display: none;
		}

		& .about-container {
			display: none;
		}

		& .back {
			position: absolute;
			z-index: 1000;
			display: block;
			top: 1rem;
			left: 1.5rem;
		}

		.folder-list {
			margin: auto;
			margin-top: 27vh;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;

			& button {
				border: none;
				border-radius: 1rem;
				padding: .5rem;
			}

			& img {
				width: 100%;
			}
		}

		& .mobile-about {
			display: flex;
			background-color: white;
			margin: 1rem;
			border-radius: 1rem;
			padding: 1rem;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			& img {
				width: 80%;
				margin-bottom: 2rem;
				border-radius: 1rem;
			}

			& .note {
				width: 100%;
				height: 20em;
				overflow: auto;
				display: flex;
				flex-direction: column;

				& img {
					width: 30%;
					border-radius: 50%;
				}
			}
		}

		& .mobile-about.hidden {
			display: none;
		}

		& .folder.hidden {
			display: none;
		}

		.crumb-container-mobile {
			display: block;
			padding: .5rem;
			border-radius: 1rem;
			text-align: center;

				& p {
					color: black;
					font-weight: 500;
					background-color: var(--secondary);
					padding: .5rem;
					border-radius: 1rem;
				}

				& span {
					background-color: var(--tertiary);
					border-radius: 1rem;
					padding: .2rem;
				}

				& button {
					border: none;
					border-radius: 1rem;
					padding: .5rem;
				}

			}
		
		.blob-list {
			margin: auto;
			margin-top: 27vh;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
		}

		& .blob {
			background-color: gray;
			border-radius: 1rem;
			margin: .2rem;
			width: 43vw;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-style: normal;
			font-weight: 600;
			text-decoration: none;
			color: black;
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: small;
		}

		& img {
			width: 40vw;
			margin: auto;
			padding: .2rem;
		}

		& table {
			display: none;
		}

		& .filter {
			display: none;
		}
	}
`