import { FC } from 'react';
import styled from 'styled-components';

import newsIcon from "../../../../assets/png/News_Icon.png"
import { FaBookmark, FaEye } from 'react-icons/fa';

import AirHandlingPDF from '../../../../assets/pdf/Air_Handling_Unit_-_IOM.pdf'

const placeholderImage = require("../../../../assets/waynestate.png")

const machine1 = require("../../../../assets/machines/machine1.png");
const machine2 = require("../../../../assets/machines/machine2.png");
const machine3 = require("../../../../assets/machines/machine3.png");

interface props {
    className?: string;
}

type Post = {
	src: string,
	title: string,
	machine: string,
	manufacturer: string,
	information: string,
	buildings: string[]
}

const NewsHome: FC <props> = ({ className }) => {

    const feedPosts: Post[] = [
		{
			src: machine2,
			title: "Installing York Air Handling Unit",
			machine: "Air Handling Unit",
			manufacturer: "York",
			information: "Installation Procedure",
			buildings: ["Faculty/Admin Building","Student Center","Chemistry Building"]
		},
		{
			src: machine1,
			title: "Troubleshooting York Air Cooled Scroll Chiller",
			machine: "Air Cooled Scroll Chiller",
			manufacturer: "York",
			information: "Trouble Shooting Procedures",
			buildings: ["Faculty/Admin Building","Student Center","Chemistry Building"]
		},
		{
			src: machine3,
			title: "Cleaver-Brooks Boiler",
			machine: "Boiler",
			manufacturer: "Cleaver-Brooks",
			information: "Cleaning Procedures",
			buildings: ["Faculty/Admin Building","Student Center","Chemistry Building"]
		},
		{
			src: machine1,
			title: "Water Control Unit Safety Procedures",
			machine: "Water Control Unit",
			manufacturer: "York",
			information: "Safety Procedures",
			buildings: ["Faculty/Admin Building","Student Center","Chemistry Building"]
		},
		{
			src: machine2,
			title: "Washing Machine Safety",
			machine: "Washing Machine",
			manufacturer: "Milnor",
			information: "Safety Procedures",
			buildings: ["Faculty/Admin Building","Student Center","Chemistry Building"]
		},
		{
			src: machine3,
			title: "JCI Chiller Troubleshooting",
			machine: "JCI Chiller",
			manufacturer: "York",
			information: "Trouble Shooting Procedures",
			buildings: ["Faculty/Admin Building","Student Center","Chemistry Building"]
		},
		{
			src: machine1,
			title: "Water Control Unit Safety",
			machine: "Water Control Unit",
			manufacturer: "York",
			information: "Safety Procedures",
			buildings: ["Faculty/Admin Building","Student Center","Chemistry Building"]
		},
	]

	const openPDF = (src: any) => {
		const pdfURL = process.env.PUBLIC_URL + src;
		window.open(pdfURL, '_blank');
	}

    return (
        <news className={className}>
            <div className="gold-topper">
				<div className="content">
					<h1>News Feed</h1>
					<img className="news-icon" src={newsIcon}></img>
					<h3>Scroll Through Newly Added & Edited Content</h3>
					{feedPosts.map((post, i) => {
						return (
							<div className="single-post">

								<div className="image-container">
									<img onClick={() => openPDF(AirHandlingPDF)} src={post.src}></img>
								</div>

								<div className="text-container">
									<h3>{post.title}</h3>
									<p>Machine: {post.machine}</p>
									<p>Manufacturer: {post.manufacturer}</p>
									<p>Information: {post.information}</p>

									<div className="buildings">
									{post.buildings.map((building, i) => {
										return <p>{building}</p>
									})}
									</div>
								</div>
								<FaBookmark size={30} />
								<FaEye size={30} />
							</div>
						)
					})}
				</div>
			</div>
        </news>
    )
}

export default styled(NewsHome)`
    height: 100%;
	display: flex;
	flex-direction: column;

	& .news-icon {
		margin-top: .5rem;
		margin-bottom: .5rem;
	}

	& h3 {
		text-align: center;
	}

	& h1 {
		color: white;
		text-align: center;
		margin: 0;
		padding: 1rem;
		font-weight: 700;
		font-style: italic;
	}
	

	img {
		width: 50%;
	}

	.news {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 18vh;
		position: absolute;
		filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
		z-index: 10;
	}

	.content {
		width: 90%;
		background-color: gray;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: auto;
		margin-top: 30vh;
		border-radius: 1rem;
		filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));

		& .single-post {
			background-color: white;
			padding: 1rem;
			margin: 1rem;
			border-radius: 1rem;
			font-weight: 500;

			& h3 {
				border-bottom: 2px solid gray;
			}
		}

		& .image-container {
			display: flex;
			margin: auto;
			align-content: center;
			justify-content: center;
			background-color: #2c2c2c;
			border-radius: 1rem 1rem 0 0;
			padding: .5rem;

			& img {
				margin: auto;
				background-repeat: no-repeat;
				width: 100%;
				height: 100%;
			}
		}

		& .buildings {
			color: green;
			font-style: italic;
		}

		& svg {
			float: right;
			margin: .5rem;
		}
	}
`